import { http } from '@/http/axios.js'
// 角色列表
export function getRoleList(params) {
  return http({
    url: '/admin/role/page',
    method: 'GET',
    params
  })
}
// 删除角色
export function removeRole(data) {
  return http({
    url: `/admin/role/delete`,
    method: 'post',
    data
  })
}
// 新增角色
export function addRole(data) {
  return http({
    url: `/admin/role/add`,
    method: 'post',
    data
  })
}
// 编辑角色
export function editRole(data) {
  return http({
    url: `/admin/role/edit`,
    method: 'post',
    data
  })
}
// 角色详情
export function getRoleDetail(params) {
  return http({
    url: `/admin/role/detail`,
    method: 'get',
    params
  })
}
// 菜单tree
export function getMenusTree(params) {
  return http({
    url: '/admin/menu/tree',
    method: 'GET',
    params
  })
}
// 授权菜单
export function grantMenu(data) {
  return http({
    url: `/admin/role/menus`,
    method: 'post',
    data
  })
}
// 角色菜单
export function getRoleMenu(roleId) {
  return http({
    url: `/auth/role/tree/${roleId}`,
    method: 'get'
  })
}
